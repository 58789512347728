import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollTop"] */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/primereact/scrolltop/scrolltop.esm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/assets/css/animate.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/assets/css/style1.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/assets/css/style.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/assets/css/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/assets/css/flaticon.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/assets/css/menu.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/assets/css/responsive.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/react-modal-video/css/modal-video.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/primereact/resources/themes/lara-light-indigo/theme.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/primereact/resources/primereact.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/primeicons/primeicons.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3712758675/src/online-ordering-system/src/components/GlobalErrorHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3712758675/src/online-ordering-system/src/components/prefetch-routes.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3712758675/src/online-ordering-system/src/context/metadata.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrderContextProvider"] */ "/codebuild/output/src3712758675/src/online-ordering-system/src/context/order.context.tsx");
