/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import fetchApi from "@/services/api-handler";
import { useEffect } from "react";

export default function GlobalErrorHandler() {
  useEffect(() => {
    window.addEventListener("error", (event) => {
      reportError(`Online Ordering: ${event?.message} ${event?.error?.stack}`);
    });

    window.addEventListener(
      "unhandledrejection",
      (event: PromiseRejectionEvent) => {
        reportError(`Online Ordering: ${event?.reason} ${event?.promise}`);
      }
    );
  }, []);

  const reportError = async (message: string) => {
    await fetchApi(
      `slack/oos/postMessage`,
      {
        env: process.env.NEXT_PUBLIC_ENV,
        msg: message,
      },
      "post"
    );
  };

  return <></>;
}
