/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function PrefetchRoutes() {
  const router = useRouter();

  useEffect(() => {
    router.prefetch("/");
    router.prefetch("about");
    router.prefetch("order-online");
    router.prefetch("menu-card");
    router.prefetch("faqs");
    router.prefetch("contact-us");
    router.prefetch("cart");
    router.prefetch("orders");
  }, []);

  return <></>;
}
